<template>
  <v-dialog v-model="dialog" width="650">
    <v-card>
      <v-card-title>Vuokrasopimuksen liitesopimukset</v-card-title>

      <!-- TABS -->
      <v-card-text>
        <v-tabs v-model="tab" color="primary" class="mb-3" fixed-tabs show-arrows>
          <v-tabs-slider color="primary"></v-tabs-slider>

          <v-tab v-for="item in tabs" :key="item">
            {{ item }}
          </v-tab>
        </v-tabs>
      </v-card-text>

      <!-- TABS -->
      <v-card-text v-if="tab == 0">
        <!-- SelectModel -->
        <select-contract-model ref="selectContractModel"></select-contract-model>
      </v-card-text>

      <v-card-text v-if="tab == 1">
        <!-- Loader -->
        <full-page-loader
          v-if="loadingList"
          text="Haetaan liitettyjä sopimuksia..."
          :size="30"
          ownClass=""
        ></full-page-loader>

        <reference-contract-list v-else></reference-contract-list>
      </v-card-text>

      <v-card-actions>
        <v-btn outlined color="error" @click="dialog = false">Poistu</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="info" @click="createDocument">Luo dokumentti</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import SelectContractModel from "../ContractModels/SelectContractModel.vue";
import ReferenceContractList from "../RentalContract/ReferenceContractList.vue";
import FullPageLoader from "@/components/FullPageLoader.vue";
import mixins from "../../mixins/mixins";

export default {
  mixins: [mixins],

  components: {
    SelectContractModel,
    ReferenceContractList,
    FullPageLoader,
  },

  props: {
    value: { type: Boolean, default: false },
    modelType: { type: String, default: "rentalContract" },
  },

  data() {
    return {
      tabs: ["Liitä sopimus", "Liitetyt"],
      tab: 0,
      referenceListFeched: false,
      loadingList: false,
    };
  },

  computed: {
    ...mapState("contract", ["refRentalContract"]),

    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },

  watch: {
    value(val) {
      if (val) {
        this.tab = 0;
        this.referenceListFeched = false;
        this.setSelectedContractModel(null);
        this.getContractModels(this.modelType);
        this.$nextTick(() => {
          this.$refs.selectContractModel.resetValidations();
        });
      }
    },

    tab(val) {
      if (val == 1 && !this.referenceListFeched) {
        this.loadingList = true;
        this.getReferenceContracts();
      }
    },
  },

  methods: {
    ...mapActions("contractmodel", ["getContractModels"]),
    ...mapActions("othercontract", ["getContractsById"]),
    ...mapMutations("contractmodel", ["setSelectedContractModel"]),

    createDocument() {
      if (this.$refs.selectContractModel.validate()) {
        this.$router.push("/other-contract/create-document?createReferenceDocument=true");
      }
    },

    async getReferenceContracts() {
      try {
        await this.getContractsById(this.refRentalContract.referenceContracts);
        this.referenceListFeched = true;
        this.loadingList = false;
      } catch (err) {
        this.showPopup(err);
        this.loadingList = false;
      }
    },
  },
};
</script>

<style scoped></style>
